import React, { useState } from 'react';

const BurgerMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Toggle the menu
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="p-4">
            {/* Burger Menu Button - Always visible */}
            <button
                onClick={handleMenuToggle}
                type="button"
                className="fixed top-4 right-4 inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-default"
                aria-expanded={isMenuOpen}
            >
                <span className="sr-only">Open main menu</span>
                <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 1h15M1 7h15M1 13h15"
                    />
                </svg>
            </button>

            {/* Menu Items - Toggle visibility */}
            <div
                className={`${isMenuOpen ? 'block' : 'hidden'} fixed top-16 right-4 w-52 bg-white shadow-md border border-gray-100 rounded-lg dark:bg-gray-800 dark:border-gray-700`}
                id="navbar-default"
            >
                <ul className="font-medium flex flex-col p-4">
                    <li>
                        <a
                            href="/"
                            className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                            aria-current="page"
                        >
                            Today's Homework
                        </a>
                    </li>
                    <li>
                        <a
                            href="/history"
                            className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                            Homework History
                        </a>
                    </li>
                    <li>
                        <a
                            href="/deadline"
                            className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                            Check Deadlines
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default BurgerMenu;
