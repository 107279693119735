import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BurgerMenu from './components/burgerMenu';

function Home() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [homeworkUrl, setHomeworkUrl] = useState('');
    const [userName, setUserName] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [tableData, setTableData] = useState();
    const [maxCol, setMaxCol] = useState(0);
    const [maxRow, setMaxRow] = useState(0);

    const name = searchParams.get("name");

    // Check session storage on component mount
    useEffect(() => {

        if (name) {
            localStorage.setItem('name', name);
            setUserName(name);
            setIsLoggedIn(true);
            fetchData(name);
        } else {
            // Check session storage if no name is in the URL
            const savedUserName = localStorage.getItem('name');
            if (savedUserName) {
                setUserName(savedUserName);
                setIsLoggedIn(true);
                fetchData(savedUserName);
            }
        }
        
    }, []);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (homeworkUrl.trim()) {
            try{
                const url = new URL(homeworkUrl);
                const searchParams = new URLSearchParams(url.search);
                const extractedName = searchParams.get('name');
                if (extractedName) {
                    localStorage.setItem('name', extractedName);
                    setIsLoggedIn(true);
                    fetchData(extractedName);
                }
            }catch(e){
                console.log(e);
            }
        }
    };

    const fetchData = async (name) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/whiteboard/get_target_table`;
        const postData = {
            user_name: name,
        };
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const dataMap = new Map();
            let maxCol = 0;
            let maxRow = 0;

            for (const entry of data.table) {
                const col = parseInt(entry.col);
                const row = parseInt(entry.row);

                if (col > maxCol) {
                    maxCol = col;
                }
                if(row > maxRow){
                    maxRow = row;
                }
                
                const data = entry.data;
                const deadline = entry.deadline;
                
                if (!dataMap.has(row)) {
                    dataMap.set(row, new Map());
                }
                // if (!dataMap.has(row+1)) {
                //     dataMap.set(row+1, new Map());
                // }
                
                dataMap.get(row).set(col, data);
                dataMap.get(row).set(col+1, deadline);
            }
            maxCol++;
            dataMap.get(1).set(3, "Deadline");
            setMaxCol(maxCol);
            setMaxRow(maxRow);
            setTableData(dataMap);
            
        } catch (error) {
            console.error('There was a problem with the POST request:', error);
        }
        
    };

    // Render the homework section
    const renderHomeworkSection = () => (
        <div className="mx-auto w-4/5 flex flex-col items-center justify-center">
            <BurgerMenu />
            {tableData ? (
                <>
                {/* Header and Description */}
                <h2 className="text-xl font-bold mb-2">Today's Homework</h2>
                <p className="mb-4">
                    Here is the list of homework assignments due today. Please ensure you complete them on time.
                </p>

                {/* Homework Table */}
                <table className="border-collapse border border-gray-300">
                    <tbody>
                    {(() => {
                        const rows = [];
                        for (let row = 1; row <= maxRow; row++) {
                        const cells = [];
                        // Iterate over rows
                        for (let col = 1; col <= maxCol; col++) {
                            // Push cell data into cells array
                            cells.push(
                            <td key={col} className="border-2 border-gray-300 p-2">
                                {tableData.get(row)?.get(col) || ''}
                            </td>
                            );
                        }
                        // Push row with cells into rows array
                        rows.push(<tr key={row}>{cells}</tr>);
                        }
                        return rows;
                    })()}
                    </tbody>
                </table>
                </>
            ) : (
                // Loading message when tableData is null
                <div className="flex items-center justify-center py-4">
                    <svg class="animate-spin h-5 w-5 mr-3 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                        ></path>
                    </svg>
                    <div className="text-lg font-medium text-gray-600">Loading...</div>
                </div>
            )}
        </div>
    );

    return (
        <div className="flex items-center justify-center min-h-screen bg-blue-50">
        {isLoggedIn ? (
            renderHomeworkSection()
        ) : (
            <form onSubmit={handleSubmit} className="p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-xl font-bold mb-4">Homework Section</h2>
                <label className="block mb-2 text-lg font-medium text-gray-700">
                    Homework Section URL:
                    <input
                    type="text"
                    value={homeworkUrl}
                    onChange={(e) => setHomeworkUrl(e.target.value)}
                    placeholder="https://homework.smv.hk/?name=ABC"
                    className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </label>
                <button
                    type="submit"
                    className="mt-4 w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Save
                </button>
            </form>
        )}
        </div>
    );
}

export default Home;
