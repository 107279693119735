import React, { useEffect } from 'react';
import BurgerMenu from './components/burgerMenu';
import { useState } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom';

function CheckDeadline() {

    const [tableData, setTableData] = useState();
    const [maxCol, setMaxCol] = useState(0);
    const [maxRow, setMaxRow] = useState(0);
    const [deadline, setDeadline] = useState(new Date().toISOString().split('T')[0]);

    const name = localStorage.getItem('name');

    useEffect( () => {
          
        fetchData();

    }, [deadline]);

    const fetchData = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/whiteboard/get_deadline`;
        const postData = {
            username: name,
            deadline: deadline
        };
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const dataMap = new Map();
            let maxCol = 1;
            let maxRow = 2;
            
            for (var i = 1; i <= 2; i++){
                dataMap.set(i, new Map());
            }

            dataMap.get(1).set(maxCol, "Subject");
            dataMap.get(2).set(maxCol, "Homework");

            for (const entry of data.table) {
                maxCol++;
                
                const data = entry.data;
                const subject = entry.subject;
                
                dataMap.get(1).set(maxCol, subject);
                dataMap.get(2).set(maxCol, data);
            }
            setMaxCol(maxCol);
            setMaxRow(maxRow);
            setTableData(dataMap);

        } catch (error) {
            console.error('There was a problem with the POST request:', error);
        }
        
    };
    
    return (
        <div className='min-h-screen bg-blue-50'>
            <BurgerMenu />        
            <div className="flex flex-col py-12 w-full items-center justify-center">
                
                <h1 className="text-3xl font-bold mb-4">{deadline}'s Deadline</h1>
                <input
                    type="date"
                    value={deadline}
                    onChange={(e) => setDeadline(e.target.value)}
                    className="mb-4 p-2 border border-gray-300 rounded"
                />
                <p className="text-lg mb-8">Below is the list of tasks and their corresponding deadlines for today. Please make sure to review and complete your tasks on time.</p>

                <table>
                    <tbody>
                        {tableData && (() => {
                            const rows = [];
                            for (let col = 1; col <= maxCol; col++) {
                                const cells = [];
                                // Iterate over rows
                                for (let row = 1; row <= maxRow; row++) {
                                    // Push cell data into cells array
                                    cells.push(<td key={row} className="border-2 border-gray-300 p-2">{tableData.get(row)?.get(col) || ''}</td>);
                                }
                                // Push row with cells into rows array
                                rows.push(<tr key={col}>{cells}</tr>);
                            }
                            return rows;
                        })()}
                    </tbody>
                </table>
                
            </div>
        </div>
    );
  
}

export default CheckDeadline;