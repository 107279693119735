import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import CheckDeadline from './CheckDeadline';
import HomeworkHistory from './HomeworkHistory';

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/deadline" element={<CheckDeadline />} />
          <Route path="/history" element={<HomeworkHistory />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
