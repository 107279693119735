export function register() {
    if ('serviceWorker' in navigator) {
        // Wait for the page to load before registering the service worker
        window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        navigator.serviceWorker
            .register(swUrl)
            .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);

            // Optional: Handle updates to the service worker
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker) {
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        // New content is available; please refresh.
                        console.log('New content is available; please refresh.');
                    } else {
                        // Content is cached for offline use.
                        console.log('Content is cached for offline use.');
                    }
                    }
                };
                }
            };
            })
            .catch((error) => {
            console.error('Error during service worker registration:', error);
            });
        });
    }
}

// This function unregisters the service worker
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
        });
    }
}