import React, { useEffect } from 'react';
import BurgerMenu from './components/burgerMenu';
import { useState } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom';

function HomeworkHistory() {

    const [tableData, setTableData] = useState();
    const [maxCol, setMaxCol] = useState(0);
    const [maxRow, setMaxRow] = useState(0);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

    const name = localStorage.getItem('name');

    useEffect( () => {
          
        fetchData();

    }, [date]);

    const fetchData = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/whiteboard/get_target_table`;
        const postData = {
            user_name: name,
            date: date
        };
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const dataMap = new Map();
            let maxCol = 0;
            let maxRow = 0;

            for (const entry of data.table) {
                const col = parseInt(entry.col);
                const row = parseInt(entry.row);

                if (col > maxCol) {
                    maxCol = col;
                }
                if(row > maxRow){
                    maxRow = row;
                }
                
                const data = entry.data;
                const deadline = entry.deadline;
                
                if (!dataMap.has(row)) {
                    dataMap.set(row, new Map());
                }
                // if (!dataMap.has(row+1)) {
                //     dataMap.set(row+1, new Map());
                // }
                
                dataMap.get(row).set(col, data);
                dataMap.get(row).set(col+1, deadline);
            }
            maxCol++;
            dataMap.get(1).set(3, "Deadline");
            setMaxCol(maxCol);
            setMaxRow(maxRow);
            setTableData(dataMap);

        } catch (error) {
            console.error('There was a problem with the POST request:', error);
        }
        
    };
    
    return (
        <div className='min-h-screen bg-blue-50'>
            <BurgerMenu />        
            <div className="flex flex-col py-12 w-full items-center justify-center">
                
                <h1 className="text-3xl font-bold mb-4">{date}'s Homework </h1>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="mb-4 p-2 border border-gray-300 rounded"
                />
                <p className="text-lg mb-8">Below is the list of tasks and their corresponding deadlines for {date}. Please make sure to review and complete your tasks on time.</p>

                <table>
                    <tbody>
                        {tableData && (() => {
                            const rows = [];
                            for (let row = 1; row <= maxRow; row++) {
                            const cells = [];
                            // Iterate over rows
                            for (let col = 1; col <= maxCol; col++) {
                                // Push cell data into cells array
                                cells.push(
                                <td key={col} className="border-2 border-gray-300 p-2">
                                    {tableData.get(row)?.get(col) || ''}
                                </td>
                                );
                            }
                            // Push row with cells into rows array
                            rows.push(<tr key={row}>{cells}</tr>);
                            }
                            return rows;
                        })()}
                    </tbody>
                </table>
                
            </div>
        </div>
    );
  
}

export default HomeworkHistory;